<template>
  <div class="overallSituation_wrap animate__animated animate__fadeInRight">
    <TitleRight :txt="'园区整体运营情况'" />
    <div class="overallSituation_cont">
      <div class="overallSituation_cont_head">
        <div>楼栋号</div>
        <div>可租赁面积(m<sup>2</sup>)</div>
        <div>出租状态</div>
        <div>出租对象</div>
      </div>
      <div class="overallSituation_list_wrap">
        <ul class="overallSituation_list overallSituation_listTop">
          <li ref="itemOne" :style="[{'margin-top': '-' + lamp + '%'}]" class="item_one"></li>
          <li v-for="(item, index) in $store.state.attract.dataInfo.list" :key="index">
            <div>{{item.house_num || '-'}}</div>
            <div>{{item.area || '-'}}</div>
            <div v-if="item.status == '已出租'" :style="{'color': '#1acde4'}">已出租</div>
            <div v-else-if="item.status == '意向选址'" :style="{'color': '#8877be'}">意向选址</div>
            <div v-else-if="item.status == '闲置'" :style="{'color': '#fff'}">闲置</div>
            <div v-else-if="item.status == '自用'" :style="{'color': '#fff'}">自用</div>
            <div v-else>{{item.status || '-'}}</div>
            <div>{{item.object || '-'}}</div>
          </li>
          <li v-for="(item, index) in $store.state.attract.dataInfo.list" :key="'i'+index">
            <div>{{item.house_num || '-'}}</div>
            <div>{{item.area || '-'}}</div>
            <div v-if="item.status == '已出租'" :style="{'color': '#1acde4'}">已出租</div>
            <div v-else-if="item.status == '意向选址'" :style="{'color': '#8877be'}">意向选址</div>
            <div v-else-if="item.status == '闲置'" :style="{'color': '#fff'}">闲置</div>
            <div v-else-if="item.status == '自用'" :style="{'color': '#fff'}">自用</div>
            <div v-else>{{item.status || '-'}}</div>
            <div>{{item.object || '-'}}</div>
          </li>
          <!-- <li ref="itemOne" :style="[{'margin-top': '-' + lamp + '%'}]" class="item_one"></li>
          <li v-for="(item, index) in $store.state.attract.dataInfo.list" :key="index">
            <div>{{item.buldNum}}</div>
            <div>{{item.area}}</div>
            <div v-if="item.status === 1" :style="{'color': '#1acde4'}">已出租</div>
            <div v-if="item.status === 2" :style="{'color': '#8877be'}">意向选址</div>
            <div v-if="item.status === 0" :style="{'color': '#fff'}">闲置</div>
            <div v-if="item.status === 3" :style="{'color': '#fff'}">自用</div>
            <div>{{item.lessor ? item.lessor : '-'}}</div>
          </li>
          <li v-for="(item, index) in $store.state.attract.dataInfo.list" :key="'i'+index">
            <div>{{item.buldNum}}</div>
            <div>{{item.area}}</div>
            <div v-if="item.status === 1" :style="{'color': '#1acde4'}">已出租</div>
            <div v-if="item.status === 2" :style="{'color': '#8877be'}">意向选址</div>
            <div v-if="item.status === 0" :style="{'color': '#fff'}">闲置</div>
            <div v-if="item.status === 3" :style="{'color': '#fff'}">自用</div>
            <div>{{item.lessor ? item.lessor : '-'}}</div>
          </li> -->
        </ul>
      </div>
      <div class="trapezoid">
        <ul class="overallSituation_list overallSituation_listBottom">
          <li class="overallSituation_listBottom_title">
            <h3>13号楼</h3>
          </li>
          <li>
            <div>楼层</div>
            <div>楼层面积(m<sup>2</sup>)</div>
            <div>出租状态</div>
            <div>出租对象</div>
          </li>
          <li v-for="(item, index) in $store.state.attract.dataInfo.otherList" :key="index">
            <div>{{item.floor}}</div>
            <div>{{item.floor_area}}</div>
            <div v-if="item.status == '已出租'" :style="{'color': '#1acde4'}">已出租</div>
            <div v-else-if="item.status == '意向选址'" :style="{'color': '#8877be'}">意向选址</div>
            <div v-else-if="item.status == '闲置'" :style="{'color': '#fff'}">闲置</div>
            <div v-else-if="item.status == '自用'" :style="{'color': '#fff'}">自用</div>
            <div v-else>{{item.status || '-'}}</div>
            <div>{{item.object ? item.object : '-'}}</div>
          </li>
          <!-- <li v-for="(item, index) in $store.state.attract.dataInfo.otherList" :key="index">
            <div>{{item.buldNum}}</div>
            <div>{{item.area}}</div>
            <div v-if="item.status === 1" :style="{'color': '#1acde4'}">已出租</div>
            <div v-if="item.status === 2" :style="{'color': '#8877be'}">意向选址</div>
            <div v-if="item.status === 0" :style="{'color': '#fff'}">闲置</div>
            <div v-if="item.status === 3" :style="{'color': '#fff'}">自用</div>
            <div>{{item.lessor ? item.lessor : '-'}}</div>
          </li> -->
          <!-- <li>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
          </li> -->
        </ul>
      </div>
      <!-- <div class="shelter"></div> -->
    </div>
  </div>
</template>
<script>
import TitleRight from '@/components/TitleRight.vue'

export default {
  components: {
    TitleRight,
  },
  mounted() {
    this.initData()
    // console.log('园区整体运行情况')
  },
  data() {
    return {
      count: 0,
      distance: 0,
      lamp: 0,
      timer: ''
    }
  },
  methods: {
    initData() {
      
      // 跑马灯
      let len = this.$store.state.attract.dataInfo.list.length * 7
      this.timer = setInterval(()=> {
        if (len >= this.lamp) {
          this.lamp += 0.1
        } else {
          this.lamp = 2
        }
      }, 10)
      // // console.log(this.$refs.itemOne)
      // setInterval(()=> {
      //   // this.distance += 1
      //   if (this.count >= 30) {
      //     // this.count = 0
      //     // let getOne = this.$store.state.attract.dataInfo.list[0]
      //     // let delOne = this.$store.state.attract.dataInfo.list.splice(0, 1)
      //     // this.$store.state.attract.dataInfo.list.push(getOne)
      //     // // console.log(delOne)
      //     // let oneItem = this.$store.state.attract.dataInfo.list[0]
      //     // this.$store.state.attract.dataInfo.list 
      //   } else {
      //     this.count += 1
      //   }
      //   // // console.log(this.count)
      // },30)
    }
  }
}
</script>
<style lang="less" scoped>
  .trapezoid{
    // width: 0px;
    // height: 0px;
    // border-top: 80px solid #0F0;
    // border-right: 80px solid #09C;
    // border-left: 80px solid #FC3;
        // transform: perspective(0.5em) rotateX(-1deg);
  }
  
  .overallSituation_list_wrap{}
  .overallSituation_wrap{
    margin-top: 25px;
    .overallSituation_listTop{
      height: 320px;
      overflow-y: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;
      // padding-top: 40px;
      // margin-top: 67px !important;
      & li:nth-child(1) {
        }
    }
    .overallSituation_cont_head {
      position: absolute;
      top: -47px;
      left: 0px;
      width: 99%;
      padding: 10px 0px;
      border: 2px solid #253867;
      display: flex;
      // justify-content: space-around;
      div{
        width: 25%;
        text-align: center;
        white-space:nowrap;
      }
    }
    .item_one{
      padding: 0 !important;
      border:0 !important;
      // animation: 2s lamp linear infinite;
    }
    @keyframes lamp {
      0% {
        margin-top: 0;
      }
      100% {
        margin-top: -100%;
      }
    }
    .overallSituation_listBottom{
      height: 191px;
      overflow-y: auto;
      // -webkit-clip-path: polygon(100% 0%, 100% 100%, 0% 85%, 0% 0%);
      position: relative;
      scrollbar-width: none;
      -ms-overflow-style: none;
    }
    .overallSituation_listBottom::before{
      // content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: #3d5d9f;
    }
    .overallSituation_cont{
      position: relative;
      margin-right: 20px;
      margin-top: 67px;
    }
    .overallSituation_list::-webkit-scrollbar {
      /*滚动条整体样式*/
      width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
      display: none;
    }
    .overallSituation_list::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
      background   : #163070;
    }
    .overallSituation_list::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
      box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      background   : #ededed;
    }
    .overallSituation_listBottom{
      // border: 1px solid #3d5d9f;
      li{
        // border: 0 solid #3d5d9f;
        div{
          padding: 3px 0 2px;
          border-bottom: 1px solid #3d5d9f;
        }
      }
    }
    .overallSituation_listBottom li div:not(:last-child) {
      border-right: 1px solid #3d5d9f;
    }
    .overallSituation_listTop{
      margin-top: 0 !important;
      // border: 1px solid #3d5d9f;
      li {
        padding: 3px 0 2px;
        border-bottom: 1px solid #253867;
      }
    }
    .overallSituation_list{
      border: 1px solid #3d5d9f;
      margin-top: 17px;
      // margin-right: 20px;
      li{
        display: flex;
        align-items: center;
        // border-bottom: 1px solid #253867;
        div{
          width: 25%;
          text-align: center;
          font-size: 16px;
          /* white-space: nowrap; */
        }
      }
    }
    // .overallSituation_listBottom{
    //   position: relative;
    // }
    .shelter{
      
      position: absolute;
      width: 102%;
      height: 23px;
      bottom: -11px;
      left: -14px;
      transform: rotate(3deg);
      background: linear-gradient(to right, #020915,#010205);

    }
    .shelter::after{
      position: absolute;
      content: '';
      width: 93.5%;
      height: 100%;
      bottom: 0;
      left: 13px;
      border-top: 1px solid #3d5d9f;
    }
    .overallSituation_listTop li:nth-child(1) {
      padding: 6px 0;
    }
    .overallSituation_listBottom li:nth-child(2) {
      div{
        padding: 6px 0;
      }
    }
    .overallSituation_listBottom_title h3 {
      width: 100%;
      text-align: center;
      padding: 7px 0;
      border-bottom: 1px solid #3d5d9f;
    }
  }
</style>