<template>
    <div class="home_right_content">
        <div class="home_right_wrapBg">
        </div>
        <div class="home_right_wrap">
          <CarCondition v-if="showBox" />
          <OverallSituation v-if="showBox" />
        </div>
        <!-- <Enterprise /> -->
    </div>
</template>
<script>
    import '@/assets/css/home/home.css'
    import CarCondition from '@/components/attractRight/CarCondition.vue'
    import OverallSituation from '@/components/attractRight/OverallSituation.vue'
    // import Enterprise from '@/components/homeRightCont/enterprise.vue'
    export default {
      components: {
        CarCondition,
        OverallSituation,
        // Enterprise
      },
      data () {
          return {
            showBox: false
          }
      },
      mounted () {
        setTimeout(() => {
            this.showBox = true
        }, 500);
      }
    }
</script>