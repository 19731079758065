<template>
  <div>
    <SwiperList :swiperInfo="swiperInfo">
      <div class="FacilitiesSwiper_list">
        <SwiperItem
          :style="{'width': 100/swiperInfo.length + '%'}"
          v-for="(item, index) in swiperInfo"
          :key="index">
          <img class="info_box_img" :src="'https://res.bjstarfish.com/' + item.photo" alt="">
          <div class="info_box">
            <h2 class="info_box_title">{{item.name}}</h2>
            <span class="info_box_main">{{item.introduce}}</span>
            <div v-if="item.address" class="info_box_bottom">
              <div>
                <img src="~@/assets/images/attractbottomLeft2.png" alt="">
              </div>
              <span>
                {{item.address}}
              </span>
            </div>
          </div>
        </SwiperItem>
      </div>
    </SwiperList>
  </div>
</template>
<script>
import SwiperList from '@/components/SwiperList.vue'
import SwiperItem from '@/components/SwiperItem.vue'

export default {
  components: {
    SwiperList,
    SwiperItem
  },
  props: {
    swiperInfo: {
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
      type: Array
    }
  }
}
</script>
<style lang="less" scoped>
  .info_box_img{
    width: 100%;
    height: 237px;
  }
  .info_box_bottom{
    display: flex;
    align-items: center;
    margin-top: 6px;
    span{
      margin-left: 8px;
      font-size: 15px;
    }
  }
  .FacilitiesSwiper_list{
    display: flex;
  }
</style>