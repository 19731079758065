<template>
	<div class="carCondition_wrap animate__animated animate__fadeInRight">
		<TitleRight :txt="'车位概况'" />
		<div class="carCondition_box">
			<div class="carCondition_box_item">
				<div class="carCondition_box_left">
					<img src="~@/assets/images/attractTopRight1.png" alt="" />
					<span>
						<!-- {{$store.state.attract.dataInfo.allCars}} -->
						<ICountUp :delay="1000" :endVal="$store.state.attract.dataInfo.allCars" :options="{ separator: ',', decimalPlaces: '0' }" />
					</span>
					<p>车位总数</p>
				</div>
			</div>
			<div class="carCondition_box_item">
				<CarConditionEchart />
				<div class="carCondition_box_itemNumber">
					<!-- <span>{{(($store.state.attract.dataInfo.leased / $store.state.attract.dataInfo.notRented ) * 100)}}%</span> -->
					<span>{{ $store.state.attract.dataInfo.occupancy_rate }}%</span>
					<p>已出租</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import TitleRight from '@/components/TitleRight.vue';
	import CarConditionEchart from '@/components/attractRight/CarConditionEchart.vue';
	import ICountUp from 'vue-countup-v2';

	export default {
		components: {
			TitleRight,
			CarConditionEchart,
			ICountUp,
		},
	};
</script>
<style lang="less" scoped>
	.carCondition_wrap {
		.carCondition_box {
			display: flex;
			margin-left: 10px;
		}
		.carCondition_box_left {
			width: 180px;
			height: 180px;
			margin-top: 28px;
			box-shadow: 0px 0px 15px #163070 inset;
			background: url('./../../assets/images/attractTopRight2.png') 100% 100% / cover;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			img {
				margin-bottom: 10px;
			}
			span {
				font-size: 50px;
				font-weight: 600;
			}
			p {
				// font-size: 1;
				color: #6d85ec;
			}
		}
		.carCondition_box_item {
			position: relative;
		}
		.carCondition_box_itemNumber {
			position: absolute;
			top: 48px;
			left: 101px;
			border: 1px dashed #92d5d5;
			font-size: 26px;
			padding: 10px 10px;
			border-radius: 50%;
			width: 55px;
			height: 55px;
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
			span {
				color: #ffc691;
				font-weight: 600;
			}
			p {
				text-align: center;
			}
		}
	}
</style>
