<template>
  <div class="facilities_wrap">
    <Title :txt="'配套设施'" />
    <div class="facilities_cont">
          <FacilitiesSwiper :swiperInfo="$store.state.attract.dataInfo.rotation">
          </FacilitiesSwiper>
        <div class="swiper-pagination"></div>
    </div>
  </div>
</template>
<script>
import Title from '@/components/Title.vue'
import FacilitiesSwiper from '@/components/attractLeft/FacilitiesSwiper.vue'
// import SwiperItem from '@/components/SwiperItem.vue'


export default {
  components: {
    Title,
    FacilitiesSwiper,
    // SwiperItem
  },
  mounted() {
    // this.gotoPage()
  },
  data() {
    return {
      swiperOption: {
         // 设置分页器
        pagination: {
          el: '.swiper-pagination',
          // 设置点击可切换
          clickable: true
        }, 
      },
      active: 0,
      swiperLeft: 0,
      timer: '',
      swiperInfo: [
        {
          src: '~@/assets/images/attractbottomLeft1.png'
        },
        {
          src: '~@/assets/images/attractbottomLeft1.png'
        },
      ]
    }
  },
  methods: {
    onSwiper() {
    },
    // gotoPage() {
    //   this.timer = setInterval(()=> {
    //     if (this.active >= this.swiperInfo.length) {
    //       this.active = 1
    //     } else {
    //       this.active += 1
    //     }
    //   }, 1000)
    // },
    // swiperBtn(index) {
    //   this.active = index + 1
    // },
    onSlideChange() {

    }
  }
}
</script>
<style lang="less" scoped>
  .facilities_wrap{
    margin-top: 40px;
    position: relative;
    height: 420px;
    .facilities_cont{
      height: 380px;
      width: 102%;
      position: absolute;
      top: 44px;
      left: 0;
      margin-left: 20px;
      .info_box{
        padding-left: 10px;
        border-left: 2px solid #647fbd;
        margin-top: 12px;
        .info_box_main{
          letter-spacing: 5.5px;
        }
        .info_box_bottom{
          display: flex;
          align-items: center;
          margin-top: 6px;
          span{
            margin-left: 8px;
            font-size: 15px;
          }
        }
      }
    }
    .swiper_wrap{
      overflow: hidden;
    }
    .swiper_list{
      display: flex;
      transition: .3s;
    }
    .swiper_pagination{
      display: flex;
      justify-content: center;
      margin-top: 20px;
      span{
        width: 10px;
        height: 10px;
        background: #9b9da6;
        border-radius: 50%;
        margin: 0 15px;
        cursor: pointer;
      }
      .active {
        background: #4482f9;
      }
      .spot {

      }
    }
    .swiper-container{
      height: 100%;
    }
    .swiper-pagination{
      // bottom: 0;
      // left: 0;
      // position: static;
      span{
        margin: 0 15px;
      }
      
    }
    .pagination_box{
      position: absolute;
      left: 0;
      bottom: 10px;
      width: 100%;
      height: 20px;
      display: flex;
      justify-content: center;
    }
    .swiper-wrapper{
      padding-bottom: 30px;
    }
    .swiper-pagination-bullet{
        margin: 0 15px;
      }
  }
</style>