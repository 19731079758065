<!--
 * @Author: PC
 * @Date: 2022-07-08 15:39:33
 * @LastEditTime: 2022-09-21 19:04:36
 * @Description: 请您输入文件描述
-->
<template>
  <div class="survey_wrap animate__animated animate__fadeInLeft">
    <Title :txt="'园区概况'" />
    <div class="survey_head">
      <div class="survey_head_item">
        <img src="~@/assets/images/attractTopLeft4.png" alt="">
        <span class="survey_head_num">
          <!-- {{$store.state.overView.dataInfo.allArea}} -->
          <ICountUp class="survey_head_num" v-if="$store.state.overView.dataInfo.allArea"
            :delay="$store.state.overView.ICountUpOption.delay" :endVal="$store.state.overView.dataInfo.allArea"
            :options="{separator: $store.state.overView.ICountUpOption.separator, decimalPlaces: '2'}" />
          <span v-else>-</span>
        </span>
        <p>总建筑面积<span>(m²)</span></p>
      </div>
      <div class="survey_head_item">
        <img src="~@/assets/images/attractTopLeft5.png" alt="">
        <span class="survey_head_num">
          <!-- {{$store.state.overView.dataInfo.buildAre}} -->
          <ICountUp class="survey_head_num" v-if="$store.state.overView.dataInfo.buildAre"
            :delay="$store.state.overView.ICountUpOption.delay" :endVal="$store.state.overView.dataInfo.buildAre"
            :options="{separator: $store.state.overView.ICountUpOption.separator, decimalPlaces: '2'}" />
          <span v-else>-</span>
        </span>
        <p>占地面积<span>(m²)</span></p>
      </div>
      <div class="survey_head_item">
        <img src="~@/assets/images/attractTopLeft6.png" alt="">
        <span class="survey_head_num">
          <!-- {{$store.state.overView.dataInfo.plotRatio}} -->
          <ICountUp class="survey_head_num" v-if="$store.state.overView.dataInfo.plotRatio"
            :delay="$store.state.overView.ICountUpOption.delay" :endVal="$store.state.overView.dataInfo.plotRatio"
            :options="{separator: $store.state.overView.ICountUpOption.separator, decimalPlaces: '1'}" />
          <span v-else>-</span>
        </span>
        <p>容积率<span></span></p>
      </div>
    </div>
    <div class="survey_bottom">
      <div class="survey_bottom_item">
        <img src="~@/assets/images/attractTopLeft01.png" alt="">
        <div class="survey_bottom_itemRight">
          <span>独栋视野</span>
          <p>一企一栋新格局</p>
        </div>
      </div>
      <div class="survey_bottom_item">
        <img src="~@/assets/images/attractTopLeft02.png" alt="">
        <div class="survey_bottom_itemRight">
          <span>全景落地飘窗</span>
          <p>全明设计</p>
        </div>
      </div>
      <div class="survey_bottom_item">
        <img src="~@/assets/images/attractTopLeft03.png" alt="">
        <div class="survey_bottom_itemRight">
          <span>L O W - E 玻璃幕墙</span>
          <p>线性方正设计</p>
        </div>
      </div>
      <div class="survey_bottom_item">
        <img src="~@/assets/images/attractTopLeft04.png" alt="">
        <div class="survey_bottom_itemRight">
          <span>6 . 4米挑空层高</span>
          <p>高规格研发配置</p>
        </div>
      </div>
      <div class="survey_bottom_item">
        <img src="~@/assets/images/attractTopLeft05.png" alt="">
        <div class="survey_bottom_itemRight">
          <span>100 % 空间使用</span>
          <p>集约高效办公</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Title from '@/components/Title.vue'
import ICountUp from 'vue-countup-v2';
export default {
  components: {
    Title,
    ICountUp
  }
}
</script>
<style lang="less" scoped>
.survey_head {
  display: flex;
  justify-content: space-around;
  padding: 23px 6px 20px;

  .survey_head_item {
    display: flex;
    flex-direction: column;
    align-items: center;

    .survey_head_num {
      font-size: 26px;
      font-weight: 600;
    }

    p {
      color: #6d85ec;
    }

    span {
      color: #fff;
      font-size: 12px;
    }
  }
}

.survey_bottom {
  margin-left: 32px;
}

.survey_bottom_item {
  display: flex;
  margin-bottom: 14px;
}

.survey_bottom_itemRight {
  display: flex;
  align-items: center;
  margin-left: 15px;
  flex: 1;
  border-bottom: 3px solid;
  border-image: linear-gradient(to right, #5e8fed, #0a112a) 30 30;

  span {
    font-size: 20px;
    margin-top: 10px;
  }

  p {
    margin-left: 24px;
    margin-top: 10px;
    color: #6d85ec;
    letter-spacing: 3px;
  }
}
</style>