<template>
    <div class="home_left_content">
      <div class="home_left_wrapBg">
      </div>
      <div class="home_left_wrap">
        <Survey></Survey>
        <Facilities></Facilities>
      </div>
        <!-- <Plan></Plan> -->
    </div>
</template>
<script>
    // import '@/assets/css/home/home.css'
    import Survey from '@/components/attractLeft/Survey.vue'
    import Facilities from '@/components/attractLeft/Facilities.vue'
    // import Plan from '@/components/homeLeftCont/plan.vue'
    export default {
      components: {
        Survey,
        Facilities,
        // Plan
      },
      data () {
          return {

          }
      },
      mounted () {
        
      }
    }
</script>