<template>
  <div class="carConditionEchart">
    <div id="carConditionEchart" :style="{width: '250px', height: '230px'}">
    </div>
    <div class="carConditionEchart_info">
      <p class="carConditionEchart_info_txt1">{{$store.state.attract.dataInfo.leased}}个</p>
      <p class="carConditionEchart_info_txt2">{{$store.state.attract.dataInfo.notRented}}个</p>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    this.drawLine()
    // console.log('车位概况')
  },
  methods: {
    drawLine() {
      let myChart = this.$echarts.init(document.getElementById('carConditionEchart'))
      myChart.setOption({
      tooltip: {
        trigger: 'item',
      },
      legend: {
        top: '74%',
        left: '31%',
        show: true,
        orient: 'vertical',
        itemHeight: 6,
        itemWidth: 6,
        textStyle: {
            fontSize: '14',
            color: '#fff',
        },
        // formatter: function(name) {
        //     var index = 0;
        //     var clientlabels = ['已出租','未出租'];
        //     var clientcounts = [9,1];
        //     clientlabels.forEach(function(value,i){
        //         if(value == name){
        //             index = i;
        //         }
        //     });
        //     // // console.log(series)
        //     return name + "  " + clientcounts[index];
        // }
      },
      series: [
        // {
        //   name:'舒张压',
        //   type:'line',
        //   stack: '总量',
        //   lineStyle:{
        //     normal:{
        //       color:'#78d528'
        //     }
        //   }
        // },
        {
          // name: 'Access From',
          type: 'pie',
          radius: ['40%', '52%'],
          center: ['55.5%','38%'],
          avoidLabelOverlap: false,
          itemStyle: {
            
            // borderRadius: 0,

            normal: {
              // borderColor: 'rgba(0,0,0, 1)',
              // borderWidth: 1,
              // shadowColor: '#4bc9a4',
              // shadowBlur: '8',
            },
            // emphasis: {
            //   show: true
            // }
          },
          
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: false,
              fontSize: '40',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [
            { value: this.$store.state.attract.dataInfo.leased, name: '已出租',
              itemStyle: {
              }
            },
            { value: this.$store.state.attract.dataInfo.notRented, name: '未出租',
              itemStyle: {
            } },
          ],
          color: [
            '#ffc691',
            '#2c4353',  
          ]
        }
      ]
    })}
  }
}
</script>
<style lang="less" scoped>
 .carConditionEchart_info{
   position: absolute;
   bottom: 12px;
  right: 54px;
   p{
      font-size: 17px;
   }
 }
 .carConditionEchart_info_txt1 {
   color: #ffc691;
 }
 .carConditionEchart_info_txt2 {
   color: #9ee6e5;
 }
</style>